<template>
  <div class="security">
    <h2 class="f22 font-weight-bold black--text mb-6">
      {{ $t("headers.security") }}
    </h2>
    <div
      class="gray-border px-6 py-5 d-flex align-center justify-space-between mb-4"
    >
      <h3 class="f18 font-weight-bold black--text d-inline-block">
        {{ $t("headers.changePassword") }}
      </h3>
      <v-btn
        height="48"
        width="200"
        depressed
        color="primary"
        class="btn-shadow radius10"
        @click="passModal = true"
      >
        <span class="f15 font-weight-bold white--text">{{
          $t("btn.changePassword")
        }}</span>
      </v-btn>
    </div>
    <v-dialog v-model="passModal" max-width="600" attach=".security">
      <v-card class="pt-8 pb-12 radius24">
        <v-btn depressed icon class="btn-close" @click="closePass">
          <v-icon color="gray darken-5">mdi-close mdi-24px</v-icon>
        </v-btn>
        <v-card flat tile class="mx-auto width100" max-width="380">
          <div class="arabic-black black--text f24 mb-2 text-center">
            {{ $t("headers.changePassword") }}
          </div>
          <div class="gray--text text-body-2 mb-6 text-center">
            {{ $t("settings.changePassDesc") }}
          </div>
          <div class="black--text">{{ $t("input.currentPassword") }}</div>
          <v-text-field
            v-model="passData.current_password"
            :error-messages="passwordCurErrors"
            :placeholder="$t('input.currentPassword')"
            :type="showOld ? 'text' : 'password'"
            outlined
            required
            color="secondary"
            height="52"
          >
            <template v-slot:append>
              <img
                class="link"
                width="24"
                height="24"
                @click="showOld = !showOld"
                :src="getIcon(showOld ? 'passShow' : 'passHide')"
              />
            </template>
          </v-text-field>
          <div class="black--text">{{ $t("input.newPassword") }}</div>
          <v-text-field
            v-model="passData.new_password"
            :error-messages="passwordErrors"
            :placeholder="$t('input.newPassword')"
            :type="show ? 'text' : 'password'"
            outlined
            required
            color="secondary"
            height="52"
          >
            <template v-slot:append>
              <img
                class="link"
                width="24"
                height="24"
                @click="show = !show"
                :src="getIcon(show ? 'passShow' : 'passHide')"
              />
            </template>
          </v-text-field>
          <div class="black--text">{{ $t("input.confirmNewPassword") }}</div>
          <v-text-field
            v-model="passData.new_password_confirm"
            :error-messages="passwordConfirmErrors"
            :placeholder="$t('input.confirmNewPassword')"
            :type="showCom ? 'text' : 'password'"
            outlined
            required
            color="secondary"
            height="52"
          >
            <template v-slot:append>
              <img
                class="link"
                width="24"
                height="24"
                @click="showCom = !showCom"
                :src="getIcon(showCom ? 'passShow' : 'passHide')"
              />
            </template>
          </v-text-field>

          <v-btn
            @click="newPass"
            class="radius10 width100"
            depressed
            height="64"
            color="primary"
            :loading="isUpdating"
          >
            <span class="text-body-1 font-weight-bold">{{
              $t("btn.update")
            }}</span>
          </v-btn>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  validations: {
    passData: {
      current_password: {
        required,
      },
      new_password: {
        required,
      },
      new_password_confirm: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  data() {
    return {
      passModal: false,
      passData: {},
      showOld: false,
      show: false,
      showCom: false,
      error: [],
      isUpdating: false,
    };
  },
  methods: {
    closePass() {
      this.passData = {};
      this.passModal = false;
    },
    getIcon(code) {
      return require(`@/assets/icon/${code}.svg`);
    },
    newPass() {
      this.error = [];
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isUpdating = true;
        this.$store
          .dispatch("newPasswordProfile", this.passData)
          .then(() => {
            this.notify("update");
            this.closePass();
          })
          .catch((e) => {
            this.error = e.response.data.error;
            this.notify();
          })
          .finally(() => {
            this.isUpdating = false;
          });
      }
    },
    notify(btn) {
      if (btn === "update") {
        this.$notify({
          title: this.$t("alert.done"),
          message: this.$t("alert.updateProfile"),
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (
        this.error.find((item) => item === "confirmation__too_often")
      ) {
        this.$notify({
          title: this.$t("alert.warning"),
          message: this.$t("alert.confirmLimit"),
          type: "warning",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      }
    },
  },
  computed: {
    passwordCurErrors() {
      const errors = [];
      if (!this.$v.passData.current_password.$dirty) {
        return errors;
      }
      !this.$v.passData.current_password.required &&
        errors.push(this.$t("inputError.passwordCurRequired"));
      this.error.find((item) => item == "current_password__required") &&
        errors.push(this.$t("inputError.passwordCurRequired"));
      this.error.find((item) => item == "current_password__invalid") &&
        errors.push(this.$t("inputError.passwordCurInvalid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.passData.new_password.$dirty) {
        return errors;
      }
      !this.$v.passData.new_password.required &&
        errors.push(this.$t("inputError.passwordRequired"));
      this.error.find((item) => item == "new_password__required") &&
        errors.push(this.$t("inputError.passwordRequired"));
      this.error.find((item) => item == "new_password__invalid") &&
        errors.push(this.$t("inputError.passwordInvalid"));
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passData.new_password_confirm.$dirty) {
        return errors;
      }
      !this.$v.passData.new_password_confirm.required &&
        errors.push(this.$t("inputError.passwordRepeatRequired"));
      if (
        !!this.passData.new_password_confirm &&
        !this.$v.passData.new_password_confirm.sameAsPassword
      ) {
        errors.push(this.$t("inputError.passwordConfirm"));
      }
      return errors;
    },
  },
  watch: {
    passModal(val) {
      if (val) {
        this.passData = {};
        this.error = [];
        this.$v.$reset();
      }
    },
  },
};
</script>
<style lang="scss"></style>
