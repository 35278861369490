<template>
  <v-card max-width="700" class="mx-auto black--text" flat>
    <h2 class="f22 font-weight-bold mb-6 text-center">
      {{ $t("headers.termsAndConditions") }}
    </h2>
    <p class="black--text">{{ $t("terms.desc") }}</p>
    <div v-for="(item, ind) in $t('terms.list')" :key="ind">
      <p>{{ ind + 1 }}. {{ item.text }}</p>
      <ul v-if="item.options" class="options ps-4">
        <li v-for="(option, i) in item.options" :key="i">
          {{ option }}
        </li>
      </ul>
    </div>
    <p class="mt-4">{{ $t("terms.footer") }}</p>
  </v-card>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.options {
  list-style-type: disc;
}
</style>
