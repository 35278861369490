<template>
  <div class="height100 relative account">
    <Loader v-if="isLoading" class="loader--center" />
    <div v-else>
      <h2 class="f22 font-weight-bold black--text mb-4">
        {{ $t("headers.account") }}
      </h2>
      <h3 class="font-weight-bold mb-1">
        {{ profile[`first_name_${langValue}`] }}
        {{ profile[`family_name_${langValue}`] }}
      </h3>
      <p class="gray--text mb-1">
        {{ $t("label.idNumber") }}: {{ profile.number }}
      </p>
      <p class="gray--text mb-8">
        {{ $t("label.dateOfBirth") }}: {{ profile.birthday }}
      </p>
      <div
        class="gray-border px-6 py-5 d-flex align-center justify-space-between mb-4"
      >
        <div>
          <div class="mb-1">
            <h3 class="f18 font-weight-bold black--text d-inline-block me-3">
              {{ $t("headers.emailAddress") }}
            </h3>
            <v-card
              flat
              class="py-1 px-3 white--text radius8 f14 d-inline-block"
              :class="isConfirmed ? 'white--text' : 'black--text'"
              width="max-content"
              :color="isConfirmed ? 'success' : 'yellow'"
            >
              {{ $t(`status.${isConfirmed ? "confirmed" : "notConfirmed"}`) }}
            </v-card>
          </div>
          <p v-if="profile.email">{{ profile.email }}</p>
        </div>
        <div class="d-flex align-center">
          <v-btn
            height="48"
            max-width="242"
            outlined
            color="primary"
            class="btn-shadow radius10 me-3"
            v-if="!isConfirmed"
            :loading="isResending"
            @click="onResendEmail"
          >
            <img
              src="@/assets/icon/reset.svg"
              alt="reset"
              class="icon--primary me-2"
            />
            <span class="f15 font-weight-bold">{{
              $t("btn.resendConfirmation")
            }}</span>
          </v-btn>
          <v-btn
            height="48"
            width="200"
            depressed
            color="primary"
            class="btn-shadow radius10"
            @click="emailModal = true"
          >
            <span class="f15 font-weight-bold white--text">{{
              $t("btn.changeEmail")
            }}</span>
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="emailModal" max-width="600" attach=".account">
        <v-card flat outlined class="px-10 py-10 relative radius24">
          <v-btn icon @click="emailModal = false" class="btn-close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="arabic-black text-h5 black--text mb-2 text-center">
            {{ $t("btn.changeEmail") }}
          </div>
          <p
            class="gray--text mb-7 text-center mx-auto"
            style="max-width: 420px"
          >
            {{ $t("profile.linkWillSend") }}
          </p>

          <v-card max-width="380" flat class="mx-auto">
            <v-form @submit.prevent="onChangeEmail">
              <p class="mb-2">{{ $t("input.newEmail") }}</p>
              <v-text-field
                v-model="newEmail"
                outlined
                color="primary"
                :placeholder="$t('input.newEmail')"
                :error-messages="newEmailErrors"
              ></v-text-field>
              <v-btn
                depressed
                height="54"
                width="100%"
                class="text-body-2 radius10 font-weight-bold"
                color="primary"
                type="submit"
                :loading="isUpdating"
              >
                {{ $t("btn.confirm") }}
              </v-btn>
            </v-form>
          </v-card>
        </v-card>
      </v-dialog>
      <div
        class="gray-border px-6 py-5 d-flex align-center justify-space-between"
      >
        <div>
          <h3 class="f18 font-weight-bold black--text d-inline-block mb-1">
            {{ $t("headers.mobileNumber") }}
          </h3>
          <p>{{ phoneCode }}{{ profile.phone_number }}</p>
        </div>
        <v-btn
          height="48"
          width="200"
          depressed
          color="primary"
          class="btn-shadow radius10"
          @click="phoneModal = true"
        >
          <span class="f15 font-weight-bold white--text">{{
            $t("btn.changeNumber")
          }}</span>
        </v-btn>
      </div>
      <v-dialog v-model="phoneModal" max-width="600" attach=".account">
        <v-card flat outlined class="px-10 py-10 relative radius24">
          <v-btn
            class="btn-back px-2"
            text
            @click="phoneStep = 1"
            v-if="phoneStep === 2"
          >
            <v-icon small color="gray" class="pt-1"
              >mdi-chevron-left mdi-24px</v-icon
            >
            <span class="gray--text text-body-1">{{ $t("btn.back") }}</span>
          </v-btn>
          <v-btn icon @click="phoneModal = false" class="btn-close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-stepper v-model="phoneStep" flat class="mx-auto" max-width="400">
            <v-stepper-items>
              <v-stepper-content step="1" class="px-0 py-0">
                <div class="arabic-black text-h5 black--text mb-12 text-center">
                  {{ $t("headers.changeMobileNumber") }}
                </div>

                <v-card max-width="380" flat class="mx-auto">
                  <v-form @submit.prevent="onChangePhone">
                    <div class="black--text">{{ $t("input.phone") }}</div>
                    <v-text-field
                      v-model="newPhone"
                      :placeholder="$t('input.phone')"
                      :error-messages="phoneErrors"
                      outlined
                      required
                      color="primary"
                      maxlength="15"
                    >
                      <template v-slot:prepend-inner>
                        <div
                          class="d-flex align-center link"
                          @click="modal = true"
                          style="min-width: 96px"
                        >
                          <img
                            v-if="!$vuetify.rtl"
                            width="32"
                            height="24"
                            :src="flagImg(data.countryCode)"
                            :srcset="flagImgSet(data.countryCode)"
                          />
                          <div class="ms-1 black--text">
                            (<span v-if="!$vuetify.rtl">+</span>
                            {{
                              flagList.find(
                                (item) => item.ct == data.countryCode
                              ).cl
                            }}
                            <span v-if="$vuetify.rtl">+</span>)
                          </div>
                          <img
                            v-if="$vuetify.rtl"
                            class="rounded-circle ms-2 me-1"
                            width="24"
                            height="24"
                            :src="flagImg(data.countryCode)"
                            :srcset="flagImgSet(data.countryCode)"
                          />
                        </div>
                      </template>
                    </v-text-field>
                    <v-btn
                      depressed
                      height="54"
                      width="100%"
                      class="text-body-2 radius10 font-weight-bold mb-4"
                      color="primary"
                      type="submit"
                      :loading="isPhoneUpdating"
                    >
                      {{ $t("btn.confirm") }}
                    </v-btn>
                  </v-form>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" class="px-0 py-0">
                <div class="arabic-black text-h5 black--text mb-2 text-center">
                  {{ $t("headers.mobileNumberVerification") }}
                </div>
                <p
                  class="gray--text mb-7 text-center mx-auto"
                  style="max-width: 420px"
                >
                  {{ $t("loginConfirm.description") }}
                </p>
                <v-card max-width="380" flat class="mx-auto">
                  <v-form @submit.prevent="onConfirmOtp">
                    <v-text-field
                      class="input-center"
                      v-model="otpCode"
                      :error-messages="codeErrors"
                      :placeholder="$t('input.codePla')"
                      outlined
                      required
                      color="secondary"
                    ></v-text-field>
                    <v-btn
                      depressed
                      height="54"
                      width="100%"
                      class="text-body-2 radius10 font-weight-bold mb-4"
                      color="primary"
                      type="submit"
                      :loading="isPhoneUpdating"
                    >
                      {{ $t("btn.confirm") }}
                    </v-btn>
                  </v-form>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
      <v-dialog max-width="340" v-model="modal">
        <v-card flat class="pa-3" height="380">
          <v-text-field
            @keyup="searchCountry"
            class="mb-2"
            v-model="country"
            hide-details
            outlined
            required
            color="secondary"
            maxlength="15"
          ></v-text-field>
          <v-card tile flat height="300" class="view-without-scroll pa-2">
            <div v-for="item in requestsFlag" :key="item.ct" class="my-3">
              <div
                :class="
                  accessCountry.find((i) => i.code == item.ct)
                    ? 'd-flex '
                    : 'd-none'
                "
                class="link"
                @click="(data.countryCode = item.ct), (modal = false)"
              >
                <img
                  alt="flag"
                  width="32"
                  :src="flagImg(item.ct)"
                  :srcset="flagImgSet(item.ct)"
                />
                <div class="clip">
                  <span class="ms-2">{{ item[lang] }}</span>
                  <span class="ms-2"
                    >(<span v-if="!$vuetify.rtl">+</span>{{ item.cl
                    }}<span v-if="$vuetify.rtl">+</span>)</span
                  >
                </div>
              </div>
            </div>
          </v-card>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/shared/Loader.vue";
import { mapState } from "vuex";
import json from "@/codes.json";

import { required } from 'vuelidate/lib/validators';

export default {
  components: { Loader },
  validations: {
    newEmail: {
      required,
    },
  },
  data() {
    return {
      isLoading: true,
      isResending: false,
      emailModal: false,
      phoneModal: false,
      isUpdating: false,
      isPhoneUpdating: false,
      newEmail: "",
      error: [],
      phoneStep: 1,
      newPhone: "",
      modal: false,
      country: "",
      flagList: json,
      requestsFlag: json,
      otpCode: "",
      data: {
        countryCode: "sa",
      },
      sid: "",
    };
  },

  mounted() {
    this.loadProfile();
    this.$store.dispatch("getPhoneCountry");
  },
  methods: {
    async loadProfile() {
      await this.$store.dispatch("getProfile");
      this.isLoading = false;
    },
    getPhoneRequestData() {
      return {
        phone_code: this.data.countryCode.toUpperCase(),
        phone_number: this.newPhone,
      };
    },
    onChangePhone() {
      this.isPhoneUpdating = true;
      this.$store
        .dispatch("updatePhone", this.getPhoneRequestData())
        .then((res) => {
          this.sid = res.data.sid;
          this.error = [];
          this.phoneStep = 2;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        })
        .finally(() => {
          this.isPhoneUpdating = false;
        });
    },
    onConfirmOtp() {
      this.isPhoneUpdating = true;
      this.$store
        .dispatch("updatePhoneOtp", this.getOtpData())
        .then((res) => {
          this.notify("phoneUpdated");
          this.phoneModal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        })
        .finally(() => {
          this.isPhoneUpdating = false;
        });
    },
    getOtpData() {
      return {
        sid: this.sid,
        otp: this.otpCode,
      };
    },

    onResendEmail() {
      this.isResending = true;
      this.$store
        .dispatch("resendEmail")
        .then(() => {
          this.notify("resent");
        })
        .catch(() => {
          this.notify("resendEmailFailed");
        })
        .finally(() => {
          this.isResending = false;
        });
    },
    getRequestData() {
      return {
        email: this.newEmail,
      };
    },
    onChangeEmail() {
      this.isUpdating = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("updateProfile", this.getRequestData())
          .then(() => {
            this.notify("emailChanged");
            this.emailModal = false;
          })
          .catch((e) => {
            this.error = e.response.data.error;
          })
          .finally(() => {
            this.isUpdating = false;
          });
      } else {
        this.isUpdating = false;
      }
    },

    notify(btn) {
      if (btn === "resent") {
        this.$notify({
          title: this.$t("alert.done"),
          message: this.$t("alert.emailResent"),
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn === "phoneUpdated") {
        this.$notify({
          title: this.$t("alert.done"),
          message: this.$t("alert.phoneUpdated"),
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn === "emailChanged") {
        this.$notify({
          title: this.$t("alert.done"),
          message: this.$t("alert.emailChanged"),
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn === "emailChangingFailed") {
        this.$notify({
          message: this.$t("alert.emailChangingFailed"),
          type: "error",
          icon: "mdi-alert-outline",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      } else if (btn === "resendEmailFailed") {
        this.$notify({
          message: this.$t("alert.confirmationTooOften"),
          type: "error",
          icon: "mdi-alert-outline",
          horizontalAlign: "center",
          verticalAlign: "top",
        });
      }
    },
    searchCountry() {
      const value = this.country.toString().toLowerCase();
      this.requestsFlag = this.flagList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    flagImgSet(code) {
      return `
        ${require(`@/assets/icon/flags/2x/img_flag_${code}.png`)} 2x,
        ${require(`@/assets/icon/flags/3x/img_flag_${code}.png`)} 3x
      `;
    },
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
      langValue: (state) => state.common.langValue,
    }),

    isConfirmed() {
      return this.profile.is_email_confirmed;
    },

    lang() {
      return this.$store.getters.langValue;
    },

    newEmailErrors() {
      const errors = [];
      if (!this.$v.newEmail.$dirty) {
        return errors;
      }
      !this.$v.newEmail.required && errors.push(this.$t('inputError.emailRequired'));
      this.error.some((item) => item == "email__required") &&
        errors.push(this.$t("inputError.emailRequired"));
      this.error.find((item) => item == "email__invalid") &&
        errors.push(this.$t("inputError.emailInvalid"));
      this.error.find((item) => item == "email__update_too_often") &&
        errors.push(this.$t("inputError.email__exists"));
      this.error.find((item) => item == "email__exists") &&
        errors.push(this.$t("inputError.emailExists"));

      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == "phone_number__required") &&
        errors.push(this.$t("inputError.phoneRequired"));
      this.error.find((item) => item == "phone_number__invalid") &&
        errors.push(this.$t("inputError.phoneInvalid"));
      this.error.find((item) => item == "phone_number__exists") &&
        errors.push(this.$t("inputError.phoneExists"));
      this.error.find((item) => item == "country__not_allowed") &&
        errors.push(this.$t("inputError.phoneAllowed"));
      this.error.find((item) => item == "action__not_allowed") &&
        errors.push(this.$t("inputError.actionNotAllowed"));
      this.error.find((item) => item == "action__limit_exceeded") &&
        errors.push(this.$t("inputError.limitExceeded"));

      return errors;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == "sid__required") &&
        errors.push(this.$t("inputError.sidRequired"));
      this.error.find((item) => item == "sid__expired") &&
        errors.push(this.$t("inputError.otpExpired"));
      this.error.find((item) => item == "otp__required") &&
        errors.push(this.$t("inputError.sidRequired"));
      this.error.find((item) => item == "otp__expired") &&
        errors.push(this.$t("inputError.otpExpired"));
      this.error.find((item) => item == "otp__not_found") &&
        errors.push(this.$t("inputError.otpNotFound"));
      this.error.find((item) => item == "bad_credentials") &&
        errors.push(this.$t("inputError.otpNotFound"));
      this.error.find((item) => item == "action__limit_exceeded") &&
        errors.push(this.$t("inputError.limitExceeded"));
      return errors;
    },
    phoneCode() {
      return json.find(
        (item) => item.ct === this.profile.phone_code.toLowerCase()
      ).cl;
    },
    accessCountry() {
      return this.$store.getters.phoneCountry;
    },
  },
  watch: {
    emailModal(val) {
      val && (this.error = []);
    },
    phoneModal(val) {
      if (!val) {
        setTimeout(() => {
          this.phoneStep = 1;
          this.otpCode = "";
          this.newPhone = "";
          this.error = [];
        }, 200);
      }
    },
    modal() {
      if (!this.modal) {
        this.requestsFlag = this.flagList;
        this.country = "";
      }
    },
  },
};
</script>
<style lang="scss">
.account {
  .btn-back {
    position: absolute;
    left: 20px;
    top: 40px;
  }
}
</style>
