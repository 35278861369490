<template>
  <v-card flat width="240" class="px-5 py-5 settings-menu--wrapper radius0">
    <v-list flat class="settings-menu">
      <v-list-item-group v-model="selectedItem" mandatory>
        <v-list-item
          v-for="item in items"
          :key="item.icon"
          @click="changeView(item.name)"
        >
          <v-list-item-icon class="my-4 mr-3">
            <v-img
              height="24"
              :src="require(`@/assets/icon/${item.icon}.svg`)"
              class="setting-icon"
            />
          </v-list-item-icon>
          <v-list-item-content class="py-2" act>
            <span class="f15">{{ item.title }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 0,
      items: [
        { icon: "account", title: this.$t("headers.account"), name: "account" },
        {
          icon: "security",
          title: this.$t("headers.security"),
          name: "security",
        },
        {
          icon: "terms",
          title: this.$t("headers.termsAndConditions"),
          name: "tos",
        },
      ],
    };
  },

  mounted() {
    this.changeView();
  },
  methods: {
    changeView(name = "account") {
      if (this.$route.query.view !== name) {
        this.$router.push({ path: "/settings", query: { view: name } });
      }
    },
  },
};
</script>
<style lang="scss">
.settings-menu {
  &--wrapper {
    border-right: 1px solid #e4e3e3 !important;
  }

  &.v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 16px !important;
    & .v-list-item__content {
      color: #909ab0;
    }
    &--active {
      background-color: #22a7ab;

      & .v-list-item__content {
        color: white;
        font-weight: bold;
      }

      & .setting-icon {
        filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(8%)
          hue-rotate(353deg) brightness(101%) contrast(104%);
      }
    }
    &::before {
      border-radius: 16px !important;
    }
  }
}
</style>
