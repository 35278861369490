<template>
  <div>
    <div class="wrap-medium height100 d-flex flex-column">
      <h1 class="mb-4 black--text f24 font-weight-bold">
        {{ $t("headers.settings") }}
      </h1>
      <div class="d-flex radius24 bg-white overflow--hidden flex-grow-1">
        <Menu />
        <div class="px-8 py-8 flex-grow-1">
          <Account v-if="viewedSetting === 'account'" />
          <Security v-if="viewedSetting === 'security'" />
          <Terms v-if="viewedSetting === 'tos'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/settings/Menu.vue";
import Account from "@/components/settings/Account.vue";
import Security from "@/components/settings/Security.vue";
import Terms from "@/components/settings/Terms.vue";

export default {
  components: { Security, Account, Menu, Terms },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  computed: {
    viewedSetting() {
      return this.$route.query.view || "account";
    },
  },
};
</script>
